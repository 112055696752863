<template>
  <div>
    <Modal
      :value="value"
      title="选择基地"
      @input="(val) => $emit('input', val)"
      :width="700"
      @on-visible-change="modalChange"
    >
      <div>
        <span class="search-item">
          <span>基地名称：</span>
          <Input
            style="width: 200px; margin-right: 10px"
            placeholder="请输入基地名称"
            clearable
            v-model="search_Name"
          ></Input>
          <Button type="primary" @click="oSearch">查询</Button>
        </span>
        <Table
          :columns="table.columns"
          :data="table.data"
          :loading="table.loading"
          @on-row-click="chooseBase"
        >
        </Table>
        <div class="footer">
          <Page
            @on-change="pageChange"
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            class="xpage"
            size="small"
          ></Page>
        </div>
      </div>
      <p slot="footer">
        <Button type="primary" style="margin-right: 20px" @click="submit"
          >提交</Button
        >
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farmId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: " ",
            width: 60,
            render: (h, { row }) => {
              return (
                <div>
                  {row.id == this.baseIndex && (
                    <a class="iconfont icon-duoxuan"></a>
                  )}
                  {row.id !== this.baseIndex && (
                    <div style="cursor:pointer; width: 14px;height: 14px;border: 1px solid #ececec; border-radios:3px"></div>
                  )}
                </div>
              );
            },
          },
          {
            title: "基地名称",
            key: "name",
            align: "center",
            minWidth: 100,
          },
          {
            title: "负责人",
            align: "center",
            width: 220,
            key: "chargePerson",
          },
          {
            title: "负责人电话",
            align: "center",
            width: 220,
            key: "chargePersonTel",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      baseIndex: 0,
      //已选基地
      baseInfo: "",
      //搜索基地
      search_Name: "",
    };
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: 2,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //选择基地
    chooseBase(row) {
      this.baseIndex = row.id;
      this.baseInfo = row.id + "/" + row.name;
    },
    submit() {
      this.$emit("on-change", this.baseInfo);
    },
    oSearch() {
      this.page.pageNo = 1;
      this.getList();
    },
    modalChange(visible) {
      if (visible) {
        this.baseIndex = this.farmId;
        this.getList();
      } else {
        this.page.pageNo = 1;
        this.bridgeName = "";
      }
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.search-item {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}
.footer {
  margin-top: 20px;
  text-align: right;
}
</style>