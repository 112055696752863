<template>
  <div class="deal-with-facilities">
    <x-table
      :no-data-text="CA('farmInfo_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @page-size-change="pageSizeChange"
      @loadExpend="loadExpend"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      fullscreen
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <Row>
          <Col span="6">
            <FormItem label="养殖基地" prop="farmName">
              <Input
                readonly
                style="width: 260px"
                placeholder="请选择养殖基地"
                @on-focus="chooseBase"
                v-model="form.farmName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="养殖用地权属">
              <Input
                style="width: 260px"
                placeholder="请输入养殖用地权属"
                v-model="form.ownershipOfLandForBreeding"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="养殖用地审批">
              <Input
                style="width: 260px"
                placeholder="请输入养殖用地审批"
                v-model="form.approvalOfAquacultureLand"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="配套消纳农用地">
              <Input
                style="width: 260px"
                placeholder="请输入配套消纳农用地"
                v-model="form.supportingAgriculturalLandConsumption"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="整治年限">
              <DatePicker
                type="year"
                placeholder="请选择整治年限"
                style="width: 260px"
                v-model="form.regulation"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="关闭状态">
              <Select
                style="width: 260px"
                placeholder="选择关闭状态"
                v-model="form.closed"
                clearable
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="关停时间">
              <DatePicker
                type="date"
                placeholder="请选择关停时间"
                style="width: 260px"
                v-model="form.closeDate"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="计划关停时间">
              <DatePicker
                type="date"
                placeholder="请选择计划关停时间"
                style="width: 260px"
                v-model="form.planCloseDate"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="是否种畜禽场">
              <Input
                style="width: 260px"
                placeholder="请输入是否种畜禽场"
                v-model="form.kolapf"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="申请监测点类型">
              <Input
                style="width: 260px"
                placeholder="请输入申请监测点类型"
                v-model="form.monitoringSiteType"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="养殖代次">
              <Input
                style="width: 260px"
                placeholder="请输入养殖代次"
                v-model="form.breedingGeneration"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="畜禽圈舍结构">
              <Input
                style="width: 260px"
                placeholder="请输入畜禽圈舍结构"
                v-model="form.solape"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="设计存栏母畜禽规模" prop="dtsoflap">
              <Input
                style="width: 260px"
                placeholder="请输入设计存栏母畜禽规模"
                v-model="form.dtsoflap"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="现商品畜禽存栏" prop="soclap">
              <Input
                style="width: 260px"
                placeholder="请输入现商品畜禽存栏"
                v-model="form.soclap"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="现母畜禽存栏" prop="tflapais">
              <Input
                style="width: 260px"
                placeholder="请输入现母畜禽存栏"
                v-model="form.tflapais"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="现种公畜禽存栏" prop="nbmlaps">
              <Input
                style="width: 260px"
                placeholder="请输入现母畜禽存栏"
                v-model="form.nbmlaps"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="现畜禽存栏总量" prop="tsolap">
              <Input
                style="width: 260px"
                placeholder="请输入现畜禽存栏总量"
                v-model="form.tsolap"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="常年存栏量" prop="annualStock">
              <Input
                style="width: 260px"
                placeholder="请输入常年存栏量"
                v-model="form.annualStock"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="存栏生猪当数量" prop="nobeis">
              <Input
                style="width: 260px"
                placeholder="请输入存栏生猪当数量"
                v-model="form.nobeis"
                clearable
              ></Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="种畜禽圈舍建筑面积" prop="baoblape">
              <Input
                style="width: 260px"
                placeholder="请输入种畜禽圈舍建筑面积"
                v-model="form.baoblape"
                clearable
              >
                <span slot="append">平方米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="商品畜禽圈舍建筑面积" prop="baoclape">
              <Input
                style="width: 260px"
                placeholder="请输入商品畜禽圈舍建筑面积"
                v-model="form.baoclape"
                clearable
              >
                <span slot="append">平方米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="有无在线监控">
              <Select
                style="width: 260px"
                placeholder="选择有无在线监控"
                v-model="form.video"
                clearable
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="有无在线监测">
              <Select
                style="width: 260px"
                placeholder="选择有无在线监测"
                v-model="form.sensor"
                clearable
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="粪污有无直排">
              <Select
                style="width: 260px"
                placeholder="选择粪污有无直排"
                v-model="form.straightLine"
                clearable
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="是否直接排入河流">
              <Select
                style="width: 260px"
                placeholder="选择是否直接排入河流"
                v-model="form.wtdditr"
                clearable
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="干粪发酵设施">
              <Input
                style="width: 260px"
                placeholder="请输入干粪发酵设施"
                v-model="form.dmff"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="沼气设施是否正常使用">
              <Input
                style="width: 260px"
                placeholder="请输入沼气设施是否正常使用"
                v-model="form.biogasFacilitiesStatus"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="沼气池" prop="biogas">
              <Input
                style="width: 260px"
                placeholder="请输入沼气池体积"
                v-model="form.biogas"
                clearable
              >
                <span slot="append">立方米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="沼气存储及利用">
              <Input
                style="width: 260px"
                placeholder="请输入沼气存储及利用"
                v-model="form.biogasStorageUtilization"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="集中供气">
              <Input
                style="width: 260px"
                placeholder="请输入集中供气"
                v-model="form.centralizedGasSupply"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="沼液还田设施">
              <Input
                style="width: 260px"
                placeholder="请输入沼液还田设施"
                v-model="form.biogasSlurryReturningFacilities"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="场内储液池体积" prop="fieldStorageTank">
              <Input
                style="width: 260px"
                placeholder="请输入场内储液池体积"
                v-model="form.fieldStorageTank"
                clearable
              >
                <span slot="append">立方米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="田间储液池体积" prop="fieldReservoir">
              <Input
                style="width: 260px"
                placeholder="请输入田间储液池体积"
                v-model="form.fieldReservoir"
                clearable
              >
                <span slot="append">立方米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="干粪储存设施">
              <Input
                style="width: 260px"
                placeholder="请输入干粪储存设施"
                v-model="form.dryManureStorageFacility"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="消纳种植作物种类及各类面积" prop="givenArea">
              <Input
                style="width: 260px"
                placeholder="请输入消纳种植作物种类及各类面积"
                v-model="form.givenArea"
                clearable
              >
                <!-- <span slot="append">平方米</span> -->
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="有机肥生产">
              <Select
                style="width: 260px"
                placeholder="选择有机肥生产"
                v-model="form.organicFertilizerProduction"
                clearable
              >
                <Option value="1">有</Option>
                <Option value="0">无</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="其他粪污处理利用设备名称及数量" prop="naqoowte">
              <Input
                style="width: 260px"
                placeholder="请输入其他粪污处理利用设备名称及数量"
                v-model="form.naqoowte"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="配套建设粪便污水贮存、处理、利用设施">
              <Input
                style="width: 260px"
                placeholder="请输入配套建设粪便污水贮存、处理、利用设施"
                v-model="form.facilitiesAcceptance"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="粪污产生量" prop="fecalWasteProduction">
              <Input
                style="width: 260px"
                placeholder="请输入粪污产生量"
                v-model="form.fecalWasteProduction"
                clearable
              >
                <span slot="append">年/吨</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="粪污综合利用量" prop="combinedUseOfFecalWaste">
              <Input
                style="width: 260px"
                placeholder="请输入粪污综合利用量"
                v-model="form.combinedUseOfFecalWaste"
                clearable
              >
                <span slot="append">年/吨</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="有机肥综合生产量"
              prop="comprehensiveOrganicFertilizerProduction"
            >
              <Input
                style="width: 260px"
                placeholder="请输入有机肥综合生产量"
                v-model="form.comprehensiveOrganicFertilizerProduction"
                clearable
              >
                <span slot="append">年/吨</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="粪污综合利用比例">
              <Input
                style="width: 260px"
                placeholder="请输入粪污综合利用比例"
                v-model="form.comprehensiveUtilizationRatioOfFeces"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="生产有机肥综合比例">
              <Input
                style="width: 260px"
                placeholder="请输入生产有机肥综合比例"
                v-model="form.cpoofp"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="近5年环保投诉">
              <Select
                style="width: 260px"
                placeholder="选择近5年环保投诉"
                v-model="form.environmentalComplaints"
                clearable
              >
                <Option value="1">有</Option>
                <Option value="0">无</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="环评类别">
              <Input
                style="width: 260px"
                placeholder="请输入环评类别"
                v-model="form.eiaCategory"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="“三同时”管理">
              <Input
                style="width: 260px"
                placeholder="请输入“三同时”管理"
                v-model="form.threeSimultaneousManagement"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="环保处理设施">
              <Input
                style="width: 260px"
                placeholder="请输入环保处理设施"
                v-model="form.environmentalTreatmentFacilities"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="环保设施投资"
              prop="investmentInEnvironmentalProtectionAcilities"
            >
              <Input
                style="width: 260px"
                placeholder="请输入环保设施投资"
                v-model="form.investmentInEnvironmentalProtectionAcilities"
                clearable
              >
                <span slot="append">万元</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="环保投资享受补贴"
              prop="environmentalProtectionInvestmentSubsidy"
            >
              <Input
                style="width: 260px"
                placeholder="请输入环保投资享受补贴"
                v-model="form.environmentalProtectionInvestmentSubsidy"
                clearable
              >
                <span slot="append">万元</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="采集人员">
              <Input
                style="width: 260px"
                placeholder="请输入采集人员"
                v-model="form.collectors"
                clearable
              >
              </Input>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="采集时间">
              <DatePicker
                v-model="form.collectorTime"
                clearable
                type="date"
                placeholder="请选择采集时间"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <choose-base
      v-model="chooseBaseShow"
      :farmId="farmId"
      @on-change="getBaseInfo"
    ></choose-base>
  </div>
</template>

<script>
import CU from "@/common/util";
import chooseBase from "./chooseBase";
import moment from "moment";
import columns from "./columns.json";
export default {
  name: "",
  components: {
    chooseBase,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "基地名称",
            minWidth: 200,
            key: "farmName",
            tooltip: true,
            fixed: 'left'
          },
          {
            title: "养殖用地审批",
            minWidth: 130,
            key: "approvalOfAquacultureLand",
          },
          {
            title: "养殖用地权属",
            minWidth: 130,
            key: "ownershipOfLandForBreeding",
          },
          {
            title: "配套消纳农用地",
            minWidth: 180,
            key: "supportingAgriculturalLandConsumption",
          },
          {
            title: "整治年限",
            minWidth: 100,
            key: "regulation",
          },
          {
            title: "关停状态",
            minWidth: 100,
            key: "closed",
            render: (h, { row }) => {
              return <span>{row.closed == 0 ? "否" : "是"}</span>;
            },
          },
          {
            title: "关停时间",
            minWidth: 100,
            key: "closeDate",
          },
          {
            title: "计划关停时间",
            minWidth: 130,
            key: "planCloseDate",
          },
          {
            title: "是否是种畜禽场",
            minWidth: 150,
            key: "kolapf",
            render: (h, { row }) => {
              return <span>{row.kolapf == 0 ? "否" : "是"}</span>;
            },
          },
          {
            title: "申请监测点类型“建议不现场采集”",
            minWidth: 260,
            key: "monitoringSiteType",
          },
          {
            title: "养殖代次",
            minWidth: 100,
            key: "breedingGeneration",
          },
          {
            title: "畜禽圈舍结构",
            minWidth: 140,
            key: "solape",
          },
          {
            title: "设计存栏母畜禽规模（头/只/羽）",
            minWidth: 260,
            key: "dtsoflap",
          },
          {
            title: "现商品畜禽存栏（头/只/羽）",
            minWidth: 260,
            key: "soclap",
          },
          {
            title: "现母畜禽存栏（头/只/羽）",
            minWidth: 260,
            key: "tflapais",
          },
          {
            title: "现种公畜禽存栏（头/只/羽）",
            minWidth: 260,
            key: "nbmlaps",
          },
          {
            title: "现畜禽存栏总量（商/种/母畜禽之和）（头/只/羽））",
            minWidth: 360,
            key: "tsolap",
          },
          {
            title: "常年存栏量（头/只/羽）",
            minWidth: 260,
            key: "annualStock",
          },
          {
            title: "存栏生猪当量数（头）",
            minWidth: 230,
            key: "nobeis",
          },
          {
            title: "种畜禽圈舍建筑面积（平方米）",
            minWidth: 260,
            key: "baoblape",
          },
          {
            title: "商品畜禽圈舍建筑面积（平方米）",
            minWidth: 260,
            key: "baoclape",
          },
          {
            title: "有无在线监控",
            minWidth: 160,
            key: "video",
            render: (h, { row }) => {
              return <span>{row.video == 0 ? "无" : "有"}</span>;
            },
          },
          {
            title: "有无在线监测（传感器）",
            minWidth: 260,
            key: "sensor",
            render: (h, { row }) => {
              return <span>{row.sensor == 0 ? "无" : "有"}</span>;
            },
          },
          {
            title: "粪污有无直排",
            minWidth: 150,
            key: "straightLine",
            render: (h, { row }) => {
              return <span>{row.straightLine == 0 ? "无" : "有"}</span>;
            },
          },
          {
            title: "是否直接排入河流",
            minWidth: 150,
            key: "wtdditr",
            render: (h, { row }) => {
              return <span>{row.wtdditr == 0 ? "否" : "是"}</span>;
            },
          },
          {
            title: "干粪发酵设施",
            minWidth: 150,
            key: "dmff",
          },
          {
            title: "沼气设施是否正常使用",
            minWidth: 260,
            key: "biogasFacilitiesStatus",
            render: (h, { row }) => {
              return (
                <span>{row.biogasFacilitiesStatus == 0 ? "否" : "是"}</span>
              );
            },
          },
          {
            title: "沼气池（立方米）",
            minWidth: 150,
            key: "biogas",
          },
          {
            title: "沼气存储及利用",
            minWidth: 150,
            key: "biogasStorageUtilization",
          },
          {
            title: "集中供气（含本户）",
            minWidth: 180,
            key: "centralizedGasSupply",
          },
          {
            title: "沼液还田设施",
            minWidth: 150,
            key: "biogasSlurryReturningFacilities",
          },
          {
            title: "场内储液池（湿粪储存设施）（立方米）",
            minWidth: 290,
            key: "fieldStorageTank",
          },
          {
            title: "田间储液池（湿粪储存设施）（立方米）",
            minWidth: 290,
            key: "fieldReservoir",
          },
          {
            title: "干粪储存设施",
            minWidth: 150,
            key: "dryManureStorageFacility",
          },
          {
            title: "消纳种植作物种类及各类面积",
            minWidth: 240,
            key: "givenArea",
          },
          {
            title: "有机肥生产 （生物床发酵）",
            minWidth: 230,
            key: "organicFertilizerProduction",
          },
          {
            title: "其他粪污处理利用设备名称及数量（选填）",
            minWidth: 330,
            key: "naqoowte",
          },
          {
            title:
              "配套建设粪便污水贮存、处理、利用设施是否通过当地县级畜牧和环保部门验收（选填）",
            minWidth: 350,
            key: "facilitiesAcceptance",
          },
          {
            title: "粪污产生量（一年/吨）",
            minWidth: 180,
            key: "fecalWasteProduction",
          },
          {
            title: "粪污综合利用量（一年/吨）",
            minWidth: 250,
            key: "combinedUseOfFecalWaste",
          },
          {
            title: "有机肥综合生产量（一年/吨）",
            minWidth: 280,
            key: "comprehensiveOrganicFertilizerProduction",
          },
          {
            title: "粪污综合利用比例",
            minWidth: 250,
            key: "comprehensiveUtilizationRatioOfFeces",
          },
          {
            title: "生产有机肥综合比例(%)",
            minWidth: 250,
            key: "cpoofp",
          },
          {
            title: "近5年环保投诉",
            minWidth: 160,
            key: "environmentalComplaints",
          },
          {
            title: "环评类别",
            minWidth: 150,
            key: "eiaCategory",
          },
          {
            title: "“三同时”管理",
            minWidth: 170,
            key: "threeSimultaneousManagement",
          },
          {
            title: "环保处理设施",
            minWidth: 150,
            key: "environmentalTreatmentFacilities",
          },
          {
            title: "环保设施投资（万元）",
            minWidth: 180,
            key: "investmentInEnvironmentalProtectionAcilities",
          },
          {
            title: "环保投资享受补贴（万元）",
            minWidth: 250,
            key: "environmentalProtectionInvestmentSubsidy",
          },
          {
            title: "采集人员",
            minWidth: 150,
            key: "collectors",
          },
          {
            title: "采集时间",
            minWidth: 150,
            key: "collectorTime",
          },
          {
            title: "操作",
            width: 150,
            fixed: "right",
            align: "center",
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("farmInfo_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("farmInfo_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        farmId: "",
        farmName: "",
        //养殖用地审批
        approvalOfAquacultureLand: "",
        //养殖用地权属
        ownershipOfLandForBreeding: "",
        //配套消纳农用地
        supportingAgriculturalLandConsumption: "",
        //整治年限
        regulation: "",
        //关停状态
        closed: "",
        //关停时间
        closeDate: "",
        //计划关停时间
        planCloseDate: "",
        //是否是种畜禽场
        kolapf: "",
        //申请监测点类型“建议不现场采集”
        monitoringSiteType: "",
        //养殖代次
        breedingGeneration: "",
        //畜禽圈舍结构
        solape: "",
        //设计存栏母畜禽规模
        dtsoflap: "",
        //现商品畜禽存栏
        soclap: "",
        //现母畜禽存栏
        tflapais: "",
        //现种公畜禽存栏
        nbmlaps: "",
        //现畜禽存栏总量
        tsolap: "",
        //常年存栏量
        annualStock: "",
        //存栏生猪当量数
        nobeis: "",
        //种畜禽圈舍建筑面积
        baoblape: "",
        //商品畜禽圈舍建筑面积（平方米）
        baoclape: "",
        //有无在线监控
        video: "",
        //有无在线监测（传感器）
        sensor: "",
        //粪污有无直排
        straightLine: "",
        //是否直接排入河流
        wtdditr: "",
        //干粪发酵设施
        dmff: "",
        //沼气设施是否正常使用
        biogasFacilitiesStatus: "",
        //沼气池（立方米）
        biogas: "",
        //沼气存储及利用
        biogasStorageUtilization: "",
        //集中供气（含本户）
        centralizedGasSupply: "",
        //沼液还田设施
        biogasSlurryReturningFacilities: "",
        //场内储液池（湿粪储存设施）（立方米）
        fieldStorageTank: "",
        //田间储液池（湿粪储存设施）（立方米）
        fieldReservoir: "",
        //干粪储存设施
        dryManureStorageFacility: "",
        //消纳种植作物种类及各类面积
        givenArea: "",
        //有机肥生产 （生物床发酵）选择
        organicFertilizerProduction: "",
        //其他粪污处理利用设备名称及数量
        naqoowte: "",
        //配套建设粪便污水贮存、处理、利用设施
        facilitiesAcceptance: "",
        //粪污产生量（一年/吨）
        fecalWasteProduction: "",
        //粪污综合利用量（一年/吨）
        combinedUseOfFecalWaste: "",
        //有机肥综合生产量（一年/吨）
        comprehensiveOrganicFertilizerProduction: "",
        //粪污综合利用比例
        comprehensiveUtilizationRatioOfFeces: "",
        //生产有机肥综合比例
        cpoofp: "",
        //近5年环保投诉 选择
        environmentalComplaints: "",
        //环评类别
        eiaCategory: "",
        //“三同时”管理
        threeSimultaneousManagement: "",
        //环保处理设施
        environmentalTreatmentFacilities: "",
        //环保设施投资（万元）
        investmentInEnvironmentalProtectionAcilities: "",
        //环保投资享受补贴（万元）
        environmentalProtectionInvestmentSubsidy: "",
        //采集人员
        collectors: "",
        //采集时间
        collectorTime: "",
      },
      rules: {
        farmName: [{ required: true, message: "请选择养殖基地" }],
        dtsoflap: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        soclap: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        tflapais: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        nbmlaps: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        tsolap: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        annualStock: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        nobeis: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        baoblape: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        baoclape: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        fieldStorageTank: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        fieldReservoir: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        //  givenArea: [
        //   {
        //     validator(rule, value, callback) {
        //       if (CU.validateNumber(value)) {
        //         callback();
        //         return;
        //       }
        //       callback(rule.message);
        //     },
        //     message: "请输入数字！",
        //     trigger: "blur",
        //   },
        // ],
        naqoowte: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        fecalWasteProduction: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        combinedUseOfFecalWaste: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        comprehensiveOrganicFertilizerProduction: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        investmentInEnvironmentalProtectionAcilities: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
        environmentalProtectionInvestmentSubsidy: [
          {
            validator(rule, value, callback) {
              let temp = ("" + value).trim();
              if (temp === "") {
                callback();
                return;
              }
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请输入数字！",
            trigger: "blur",
          },
        ],
      },
      //选择基地弹框
      chooseBaseShow: false,
      farmId: "",
      columns: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增基地附属",
            ca: "farmInfo_add",
          },
          width: 220,
          loadModel: {
            url: "https://castoff.hogdata.cn/app/养殖场一场一档.xlsx",
          },
          loadEnter: {
            loadName: "导入",
            // ca: "farmInfo_import",
          },
          loadExpend: {
            loadExpendName: "导出",
            ca: "farmers_export",
          },
          filterBox: [
            {
              conditionName: "基地名称",
              component: "input",
              field: "farmName",
              defaultValue: "",
            },
            {
              conditionName: "用地审批",
              component: "input",
              field: "approvalOfAquacultureLand",
              defaultValue: "",
            },
            {
              conditionName: "用地权属",
              component: "input",
              field: "ownershipOfLandForBreeding",
              defaultValue: "",
            },
            {
              conditionName: "养殖代次",
              component: "input",
              field: "breedingGeneration",
              defaultValue: "",
            },
            {
              conditionName: "干粪发酵设施",
              component: "input",
              field: "dmff",
              defaultValue: "",
            },
            {
              conditionName: "畜禽圈舍结构",
              component: "input",
              field: "solape",
              defaultValue: "",
            },
            {
              conditionName: "采集人员",
              component: "input",
              field: "collectors",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("siteType", 2);
        this.$post(this.$api.FAMRMING.FARM_INFORMATION_UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList();
        });
      };
      input.click();
    },
    //导出
    loadExpend() {
      let columns = this.columns.map((item) => item.title);
      let data = this.table.data.map((row) => {
        let rowData = this.columns.map((column) => {
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "养殖基地附属信息");
    },
    add() {
      this.farmId = "";
      this.modal = {
        show: true,
        title: "新增信息",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    //基地列表
    getBaseList() {
      this.$post(this.$api.BASE_MANAGE.LIST).then((res) => {
        console.log(res);
      });
    },
    getList() {
      if (!this.CA("farmInfo_check")) return;
      this.table.loading = true;
      this.$post(this.$api.FARMINFORMATION.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    chooseBase() {
      this.chooseBaseShow = true;
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.form.regulation &&
          (params.regulation = moment(this.form.regulation).format("YYYY"));
        this.form.closeDate &&
          (params.closeDate = moment(this.form.closeDate).format("YYYY-MM-DD"));
        this.form.planCloseDate &&
          (params.planCloseDate = moment(this.form.planCloseDate).format(
            "YYYY-MM-DD"
          ));
        this.form.collectorTime &&
          (params.collectorTime = moment(this.form.collectorTime).format(
            "YYYY-MM-DD"
          ));
        let url;
        if (params.id) {
          url = this.$api.FARMINFORMATION.UPDATE;
        } else {
          url = this.$api.FARMINFORMATION.ADD;
        }
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.farmId = row.farmId;
      this.modal = {
        show: true,
        title: "编辑信息",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.FARMINFORMATION.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    getBaseInfo(val) {
      if (val) {
        this.form.farmId = val.split("/")[0];
        this.form.farmName = val.split("/")[1];
      }
      this.chooseBaseShow = false;
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        farmId: "",
        farmName: "",
        approvalOfAquacultureLand: "",
        ownershipOfLandForBreeding: "",
        supportingAgriculturalLandConsumption: "",
        regulation: "",
        closed: "",
        closeDate: "",
        planCloseDate: "",
        kolapf: "",
        monitoringSiteType: "",
        breedingGeneration: "",
        solape: "",
        dtsoflap: "",
        soclap: "",
        tflapais: "",
        nbmlaps: "",
        tsolap: "",
        annualStock: "",
        nobeis: "",
        baoblape: "",
        baoclape: "",
        video: "",
        sensor: "",
        straightLine: "",
        wtdditr: "",
        dmff: "",
        biogasFacilitiesStatus: "",
        biogas: "",
        biogasStorageUtilization: "",
        centralizedGasSupply: "",
        biogasSlurryReturningFacilities: "",
        fieldStorageTank: "",
        fieldReservoir: "",
        dryManureStorageFacility: "",
        givenArea: "",
        organicFertilizerProduction: "",
        naqoowte: "",
        facilitiesAcceptance: "",
        fecalWasteProduction: "",
        combinedUseOfFecalWaste: "",
        comprehensiveOrganicFertilizerProduction: "",
        comprehensiveUtilizationRatioOfFeces: "",
        cpoofp: "",
        environmentalComplaints: "",
        cpoofp: "",
        eiaCategory: "",
        threeSimultaneousManagement: "",
        environmentalTreatmentFacilities: "",
        investmentInEnvironmentalProtectionAcilities: "",
        environmentalProtectionInvestmentSubsidy: "",
        collectors: "",
        collectorTime: "",
      };
      this.$refs.form.resetFields();
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (
          column.key == "dtsoflap" ||
          column.key == "soclap" ||
          column.key == "tflapais" ||
          column.key == "nbmlaps" ||
          column.key == "tsolap" ||
          column.key == "annualStock" ||
          column.key == "baoclape" ||
          column.key == "nobeis" ||
          column.key == "baoblape" ||
          column.key == "biogas" ||
          column.key == "baoblape" ||
          column.key == "organicFertilizerProduction" ||
          column.key == "naqoowte" ||
          column.key == "givenArea" ||
          column.key == "fieldStorageTank" ||
          column.key == "fieldReservoir" ||
          column.key == "fecalWasteProduction" ||
          column.key == "combinedUseOfFecalWaste" ||
          column.key == "comprehensiveOrganicFertilizerProduction" ||
          column.key == "investmentInEnvironmentalProtectionAcilities" ||
          column.key == "environmentalProtectionInvestmentSubsidy"
        ) {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v.toFixed(2),
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
  },
  mounted() {
    this.getList();
    this.columns = columns;
  },
};
</script>

<style lang="less" scoped>
.deal-with-facilities {
  width: 100%;
  height: 100%;
}
</style>